<template>
  <div class="w-full max-w-[480px] overflow-hidden mx-auto bg-white-0 relative">
    <common-header v-if="showTopbar" />
    <slot />
    <common-bottom-bar v-if="showTopbar && path !== '/tier'" :current-router="path" />
    <profile-modal
      v-if="showTopbar"
      :class="['fixed bottom-0 left-0 z-[11] transition-all duration-300', showProfile ? 'translate-y-0' : 'translate-y-full']"
    />
    <!-- <home-popup-intro :visible="showPopupIntro" @close="popup = false" /> -->
    <invite-commission-intro-banner :visible="showPopupIntro" :commisson-pending="dataCommission?.pending_commission" @close="popup = false" />
    <div v-show="showProfile" class="fixed inset-0 z-[10]" />
  </div>
</template>
<script setup>
import { provide } from 'vue'
import { SHOW_POPUP_SPLASH } from '~/constants/user'
import { binanceSmartChain } from '@reown/appkit/networks'
import { createAppKit } from '@reown/appkit/vue'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { useDisconnect } from '@web3modal/ethers/vue'

import Store from '~/store/store'
import UserInfo from '~/store/user'
import useInviteStore from '~/store/invite'
import { subscribeEventsCallback } from '~/wallets/siwe'

const useInvite = useInviteStore()
const { dataCommission } = storeToRefs(useInvite)
const router = useRouter()
const route = useRoute()
const { isBoost, boosting, adapter } = storeToRefs(UserInfo())
const { viewPortPC, showProfile } = storeToRefs(Store())
const showTopbar = ref(false)
const notHeader = ['/', '/onboard', '/checking-account', '/welcome', '/not-data', '/total-gum']
const overflowHidden = ref(['/leaderboard', '/invite', '/shop'])
const path = ref(window.location.pathname)
const gtmId = useRuntimeConfig().public.gtm
const popupWelcom = useRuntimeConfig().public.popup_splash
const popup = ref(true)
const showPopupIntro = computed(() => {
  const allow = Number(popupWelcom.status)
  return allow && popup.value && path.value.includes('/home') && dataCommission.value?.pending_commission_val
})

// 1. Your Reown Cloud project ID
const projectId = useRuntimeConfig().public.wallet_project_id

const etherAdapter = new EthersAdapter()

const metadata = {
  name: 'Gumart DApp',
  description: 'Gumart DApp',
  url: 'https://gumart-dapp.bekisoft.com', // url must match your domain & subdomain
  icons: ['https://gumart-dapp.bekisoft.com/favicon.ico']
}

const modal = createAppKit({
  adapters: [etherAdapter],
  projectId,
  metadata,
  networks: [binanceSmartChain],
  defaultNetwork: binanceSmartChain,
  features: {
    analytics: false, // Optional - defaults to your Cloud configuration
    email: false,
    socials: false,
    swaps: false,
    onramp: false,
    history: false
  }
  // siweConfig // pass your siweConfig
})

modal.subscribeEvents(subscribeEventsCallback)

const openModalWallet = () => {
  modal.open()
}

provide('modal', modal)
provide('etherAdapter', etherAdapter)
adapter.value = etherAdapter

useHead({
  script: [
    {
      hid: 'gtm',
      children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`,
      type: 'text/javascript'
    }
  ],
  noscript: [
    {
      children: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      body: true
    }
  ]
})
watch(
  router.currentRoute,
  () => {
    path.value = window.location.pathname
    showTopbar.value = !notHeader.includes(path.value)
    if (path.value.includes('/home')) {
      isBoost.value = boosting.value
    } else {
      isBoost.value = false
    }
    if (overflowHidden.value.includes(path.value)) {
      document.body.style.overflow = 'initial'
    } else {
      document.body.style.overflow = 'hidden'
    }
  },
  { immediate: true }
)
watch(
  showProfile,
  () => {
    if (showProfile.value) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  },
  { immediate: true }
)
const checkShowPopup = () => {
  const now = (new Date().getTime() / 1000).toFixed()
  const prev = Number(window.localStorage.getItem(SHOW_POPUP_SPLASH))
  return now >= prev + 24*60*60
}
onMounted(() => {
  popup.value = checkShowPopup()
  viewPortPC.value = window.innerHeight < 700
  document.addEventListener(
    'wheel',
    function (event) {
      if (event.ctrlKey) {
        event.preventDefault()
      }
    },
    { passive: false }
  )
  document.addEventListener('keydown', function (event) {
    if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-')) {
      event.preventDefault()
    }
  })
})
</script>
<style>
.nuxt-devtools-panel {
  display: none !important;
}
</style>
