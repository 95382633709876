<template>
  <div class="fixed bottom-0 w-full z-[12] pc-size">
    <div class="max-w-[768px] mx-auto flex px-4 py-2 justify-between w-full shadow_filter relative">
      <div
        class="flex justify-center items-center absolute inset-0 rounded-t-[2rem] overflow-hidden"
        :class="{
          '[box-shadow:0px_-4px_10px_0px_rgba(153,_170,_184,_0.40)] !bg-transparent': active == '/home' && dataShowVideo.includes(dataHome.tick_level)
        }"
      >
        <img
          v-if="(((!isBoost && active !== '/home') || (!isBoost && active === '/home' && !dataShowVideo.includes(dataHome.tick_level)) || active === '/profile') && (!autoBoost || active !== '/home')) || showProfile"
          src="../../assets/icons/bg-bottom-bar-white.svg"
          class="h-full object-cover shadow_filter"
        >
        <img v-else-if="autoBoost && active === '/home'" src="../../assets/icons/bg-bottom-bar-black-boost.svg" class="h-full object-cover" />
        <img v-else src="../../assets/icons/bg-bottom-bar-black.svg" class="h-full object-cover shadow_filter rounded-t-[2rem]" />
      </div>
      <div class="grid grid-cols-5 w-full gap-2">
        <button v-for="item in menu" :key="item.id" class="relative" @click="handleAction(item)">
          <div class="flex flex-col gap-[0.125rem] items-center justify-center">
            <div :class="['w-1 h-1 rounded-[50%]', active === item.link && item.id !== 2 ? 'bg-green-1' : '']" />
            <div
              :class="[
                item.id !== 2 ? 'w-6 h-6' : 'w-[4rem] h-[4rem] absolute -translate-y-[50%]',
                { shadow_filter: active !== '/home' || !dataShowVideo.includes(dataHome.tick_level) }
              ]"
            >
              <img
                :src="
                  (isBoost || dataShowVideo.includes(dataHome.tick_level)) && active === '/home' && !showProfile ? item.icon_boost
                  : active !== item.link ? item.icon
                    : item.icon_active
                "
                class="w-full h-full"
              >
            </div>
            <p
              :class="[
                'text-[0.625rem]',
                active === item.link && !isBoost ? 'text-green-1'
                : (isBoost || (dataShowVideo.includes(dataHome.tick_level) && isBoost && active === '/home')) && !showProfile ? 'text-[#C3CED5]'
                  : 'text-gray-1'
              ]"
            >
              {{ item.title }}
            </p>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { iconsBottomBar } from '@/assets/icons/define_icons'
import { RankStatus } from '~/constants/user'
import Store from '~/store/store'
import UserInfo from '~/store/user'

interface Props {
  currentRouter: string
}
interface Menu {
  icon: string
  icon_active: string
  title: string
  icon_boost: string
  link: string
  id: number
}

const emits = defineEmits(['show_profile'])
const router = useRouter()
const { showProfile } = storeToRefs(Store())
const { dataHome, isBoost, autoBoost } = storeToRefs(UserInfo())
const dataShowVideo = [RankStatus.oneYear, RankStatus.fourYear]
const props = withDefaults(defineProps<Props>(), {
  currentRouter: ''
})

const active = ref('/home')
const menu: Menu[] = [
  {
    icon: iconsBottomBar.people,
    icon_active: iconsBottomBar.peopleActive,
    title: 'Partners',
    icon_boost: iconsBottomBar.peopleBoost,
    link: '/partners',
    id: 0
  },
  {
    icon: iconsBottomBar.guglobal,
    icon_boost: iconsBottomBar.guglobalBoost,
    icon_active: iconsBottomBar.guglobalActive,
    title: 'GuGlobal',
    link: '/mission',
    id: 1
  },
  {
    icon: iconsBottomBar.logo,
    icon_active: iconsBottomBar.logo,
    icon_boost: iconsBottomBar.logo,
    title: '',
    link: '/home',
    id: 2
  },
  {
    icon: iconsBottomBar.cart,
    icon_boost: iconsBottomBar.cartBoost,
    icon_active: iconsBottomBar.cartActive,
    title: 'Shop',
    link: '/shop',
    id: 3
  },
  {
    icon: iconsBottomBar.wallet,
    icon_boost: iconsBottomBar.walletBoost,
    icon_active: iconsBottomBar.walletActive,
    title: 'Wallet',
    link: '/wallet',
    id: 4
  }
]
watch(
  () => props.currentRouter,
  () => {
    active.value = props.currentRouter
  }
)
watch(
  showProfile,
  () => {
    if (!showProfile.value) {
      active.value = window.location.pathname
    } else {
    }
  },
  { immediate: true }
)

const handleAction = (data: Menu) => {
  router.push(data.link)
  showProfile.value = false
  active.value = data.link
}
</script>
<style scoped lang="scss">
.shadow_filter {
  filter: drop-shadow(0 0 10px rgba(153, 170, 184, 0.4));
}
</style>
