<template>
  <button
    class="px-2 py-1 rounded-[2rem] flex gap-2 w-fit justify-center items-center rounded-[2rem] bg-[linear-gradient(110deg,_#001528_0%,_#6AA8E0_100%)]"
    @click="handleShowProfile()"
  >
    <!-- <img :src="wallet" class="size-[1rem]" alt="wallet icon" /> -->
    <img class="size-[1rem] aspect-square rounded-[50%]" :src="userInfo.avatar || avatar" alt="avatar" />
    <p class="text-[#FFF] font-[Poppins] text-[0.875rem] not-italic font-medium leading-[normal]">
      {{ shortenText(userInfo?.username || '--', 4, 3) }}
    </p>
  </button>
</template>
<script setup>
import wallet from '@/assets/icons/wallet-icon.svg'
import Store from '~/store/store'
import UserInfo from '~/store/user'

const { userInfo } = storeToRefs(UserInfo())
const { showProfile } = storeToRefs(Store())

const visibleDialog = ref(false)

const router = useRouter()

const handleShowProfile = () => {
  showProfile.value = !showProfile.value
}
</script>
