<template>
  <el-dialog :model-value="props.visible" width="100%" modal-class="popup-intro" @close="handleClose()">
    <template #default>
      <div class="max-w-[480px] mx-auto flex flex-col items-center justify-center">
        <div class="relative" :class="[!viewPortPC ? 'w-full' : 'w-[20rem]']">
          <img :src="mainImageSplash" class="aspect-square" :class="[!viewPortPC ? 'w-full' : 'w-[20rem]']" >
          <div
            class="absolute top-[25%] left-[50%] -translate-x-[50%] flex flex-col items-center sf:gap-[2rem] justify-start gap-[1rem] w-[80%]"
            :class="{ '!gap-[1rem]': !viewPortPC }"
          >
            <h3
              class="text-white-0 [text-shadow:0px_3.548px_3.548px_rgba(0,_0,_0,_0.91),_1.292px_1.292px_15.08px_#000] sf:text-[2rem] md:text-[1.7rem] font-bold leading-[92.5%] tracking-[0.00669rem] uppercase"
              :class="[!viewPortPC ? 'text-[1.33863rem]' : 'text-[1.2rem]']"
            >
              Don't miss out!
            </h3>
            <div class="flex flex-col sf:gap-[0.75rem] gap-[0.32rem] items-center justify-start">
              <div class="text-white-0 md:text-[1rem] sf:text-[1rem] text-[0.75rem] not-italic font-medium text-center leading-[normal]">
                <p class="flex items-center gap-[0.25rem]">
                  <span>You have</span>
                  <span class="text-white text-stroke-green leading-none">{{ commissonPending }}</span>
                  <span>waiting </span>
                </p>
                <p>for you!</p>
              </div>
              <p class="text-white-0 md:text-[1rem] sf:text-[1rem] text-[0.625rem] not-italic font-medium leading-[normal] w-[82%] text-center">
                Purchase a package now to receive your pending commissions
              </p>
            </div>
          </div>
          <button :disabled="!popup_splash.url" class="absolute bottom-[19%] left-[50%] -translate-x-[50%] w-[55%] h-[3.5rem]" @click="handleActionPopup()" />
        </div>
        <div
          :class="[!viewPortPC ? 'w-full' : 'w-[20rem]']"
          class="flex control_popup justify-between px-4 py-2 rounded-b-[0.5rem] bg-[linear-gradient(39deg,#66E0D9_0%,#0054A1_100%)]"
        >
          <el-checkbox v-model="stopAppearing" label="Don't show for 24 hours" />
          <button class="" @click="handleClose()">
            <img :src="iconsCommon.closeWhite" class="size-8">
          </button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import { iconsCommon } from '@/assets/icons/define_icons'
import { imgsPopupIntro } from '@/assets/images/define_images'
import { SHOW_POPUP_SPLASH } from '~/constants/user'
import Store from '~/store/store'
const { viewPortPC } = storeToRefs(Store())
const emits = defineEmits(['close'])
const popup_splash = useRuntimeConfig().public.popup_splash
const router = useRouter()

interface Props {
  visible: boolean
  commissonPending: string
}
const props = withDefaults(defineProps<Props>(), {
  visible: true,
  commissonPending: '$0'
})
const stopAppearing = ref(false)
const mainImageSplash = computed(() => {
  const img = imgsPopupIntro.comminssionPending
  // if(!popup_splash.url){
  //   return img = imgsPopupIntro.welcome
  // }
  // switch(popup_splash.keyword){
  //   case 'join_now':
  //     img = imgsPopupIntro.joinNow
  //     break
  //   default:
  //     img = imgsPopupIntro.welcome
  //     break
  // }
  return img
})
const handleSetLocalTimer = () => {
  const now = (new Date().getTime() / 1000).toFixed()
  window.localStorage.setItem(SHOW_POPUP_SPLASH, now.toString())
}
const handleClose = () => {
  if (stopAppearing.value) {
    handleSetLocalTimer()
  }
  emits('close')
}
const handleActionPopup = () => {
  if (popup_splash.url) {
    router.push(popup_splash.url)
  }
  emits('close')
}
</script>
<style lang="scss">
.popup-intro {
  @apply overflow-hidden;
  & > .el-overlay-dialog {
    @apply bg-[#0F4741] bg-opacity-20 backdrop-blur-lg overflow-hidden flex items-center justify-center;
    ::-webkit-scrollbar {
      width: 0;
    }
  }
  .el-dialog {
    @apply p-3 m-0 bg-transparent shadow-none;
    .el-dialog__headerbtn {
      @apply hidden;
    }
    .el-dialog__header.show-close {
      @apply p-0;
    }
    .el-dialog {
      @apply p-0;
    }
  }
  .control_popup {
    .el-checkbox__input {
      @apply border-transparent;
      .el-checkbox__inner {
        @apply size-5 bg-transparent border-white-0 border-[2px];
        &::after {
          @apply size-2;
        }
      }
    }
    .is-checked {
      .el-checkbox__inner {
        @apply bg-green-1 #{!important};
        &::after {
          @apply border-[2px] left-[5px] w-1;
        }
      }
    }
    .el-checkbox__label {
      @apply text-white-0 font-semibold;
    }
  }
}

.text-stroke-green {
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: #0ba892;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
}
</style>
